import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 33 30">
    <path
      fill={color}
      d="M14.5,15.5c0-0.5,0.4-0.9,0.9-0.9h2c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9h-2C15,16.4,14.5,16,14.5,15.5z M23.6,24.5
	h2c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-2c-0.5,0-0.9,0.4-0.9,0.9C22.6,24.1,23,24.5,23.6,24.5z M23.6,16.4h2
	c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-2c-0.5,0-0.9,0.4-0.9,0.9C22.6,16,23,16.4,23.6,16.4z M23.6,20.5h2
	c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-2c-0.5,0-0.9,0.4-0.9,0.9C22.6,20.1,23,20.5,23.6,20.5z M15.5,20.5h2
	c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-2c-0.5,0-0.9,0.4-0.9,0.9C14.5,20.1,15,20.5,15.5,20.5z M7.4,20.5h2
	c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-2c-0.5,0-0.9,0.4-0.9,0.9C6.5,20.1,6.9,20.5,7.4,20.5z M15.5,24.5h2
	c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-2c-0.5,0-0.9,0.4-0.9,0.9C14.5,24.1,15,24.5,15.5,24.5z M7.4,24.5h2
	c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-2c-0.5,0-0.9,0.4-0.9,0.9C6.5,24.1,6.9,24.5,7.4,24.5z M7.4,16.4h2
	c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-2c-0.5,0-0.9,0.4-0.9,0.9C6.5,16,6.9,16.4,7.4,16.4z M32.6,5.8v20.9
	c0,1.6-1.3,2.8-2.8,2.8H3.2c-1.6,0-2.8-1.3-2.8-2.8V5.8C0.4,4.2,1.6,3,3.2,3h2.3V1.4c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9V3
	h8.2V1.4c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9V3h8.2V1.4c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9V3h2.3C31.4,3,32.6,4.2,32.6,5.8z
	 M30.7,11.4H2.3v15.3c0,0.5,0.4,0.9,0.9,0.9h26.6c0.5,0,0.9-0.4,0.9-0.9V11.4z M30.7,5.8c0-0.5-0.4-0.9-0.9-0.9h-2.3v1.6
	c0,0.5-0.4,0.9-0.9,0.9S25.6,7,25.6,6.4V4.9h-8.2v1.6c0,0.5-0.4,0.9-0.9,0.9S15.6,7,15.6,6.4V4.9H7.4v1.6c0,0.5-0.4,0.9-0.9,0.9
	C5.9,7.4,5.5,7,5.5,6.4V4.9H3.2c-0.5,0-0.9,0.4-0.9,0.9v3.7h28.5V5.8z"
    />
  </svg>
);
